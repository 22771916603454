import Image from "next/legacy/image"
import MailChimpForm from "~/components/modules/MailChimpForm"
import { SIHLoader } from "~/utils"

import { Container, Flex, Grid, GridItem, Heading, Text, VStack } from "@chakra-ui/layout"

const Newsletter = (): JSX.Element => {
  return (
    <Flex position="relative" zIndex={0} bgColor="black" data-cy="newsletter">
      <Flex position="absolute" top="0" left="0" right="0" bottom="0" zIndex={-10} opacity={0.25}>
        <Image
          loader={SIHLoader}
          src="landing/homes-bg-dark.png"
          alt="Newsleter section background"
          layout="fill"
          objectFit="cover"
        />
      </Flex>
      <Container maxW="container.xl" my="90px">
        <Grid gridTemplateColumns={["1fr", "1fr", "1fr", "1fr 1fr"]} gap="50" alignItems="center">
          <GridItem>
            <VStack align="left" spacing="4">
              <Heading as="h2" fontWeight="500" color="white">
                Sign up for our newsletter
              </Heading>
              <Text color="white" fontSize="18px">
                Join thousands and sign up for our 3-minute weekly newsletter. Learn about
                HonestDoor updates, real estate trends, and entertaining news.
              </Text>
            </VStack>
          </GridItem>
          <GridItem display="flex" justifyContent="center">
            {/* TODO: Swap out for input variant */}
            <MailChimpForm
              size="lg"
              inputProps={{
                bgColor: "white",
                w: {
                  base: "100%",
                  lg: 515,
                },
                rounded: "full",
              }}
              buttonProps={{ size: "lg", rounded: "full" }}
              maxW={515}
            />
          </GridItem>
        </Grid>
      </Container>
    </Flex>
  )
}

export default Newsletter
