import React, { useEffect, useState } from "react"
import { FaArrowDown, FaArrowUp } from "react-icons/fa"

import { BoxProps, List, ListIcon, ListItem, ListItemProps, ListProps } from "@chakra-ui/react"

export type TVerticalSelectProps = ListProps & {
  selectOptions: string[]
  onChange: (selected: number) => void
  arrows: boolean
  activeSlide?: number
  itemFontSize?: any
}

const VSListItem = ({
  children,
  selected = false,
  textAlign,
  onClick,
  id,
}: ListItemProps &
  BoxProps & {
    selected?: boolean
  } & { itemFontSize?: string }) => {
  return (
    <ListItem
      id={id}
      bgColor="transparent"
      textColor={selected ? "whiteAlpha.900" : "whiteAlpha.600"}
      textAlign={textAlign}
      paddingX="1em"
      marginY=".8em"
      fontSize="18px"
      fontWeight={selected ? "500" : "normal"}
      cursor="pointer"
      onClick={onClick}
      userSelect="none"
      _hover={
        !selected && {
          textColor: "whiteAlpha.900",
        }
      }
      _active={{
        textColor: "whiteAlpha.900",
      }}
    >
      {children}
    </ListItem>
  )
}

const VerticalSelect = ({
  selectOptions,
  onChange,
  textAlign,
  arrows,
  itemFontSize,
  activeSlide,
}: TVerticalSelectProps): JSX.Element => {
  const [selectedItemIndex, setSelectedItemIndex] = useState(0) //start at top of list

  useEffect(() => {
    setSelectedItemIndex(activeSlide)
  }, [activeSlide])

  const handleSelect = (e, i, listLength) => {
    const linkStates = index => {
      setSelectedItemIndex(index)
      onChange(index)
    }
    // selectOption
    if (i > -1 && i !== selectedItemIndex) {
      linkStates(i)
    }
    // up i--
    if (e.currentTarget.id === "vs-up") {
      return selectedItemIndex > 0 ? linkStates(selectedItemIndex - 1) : linkStates(listLength - 1)
    }
    // down = i++
    if (e.currentTarget.id === "vs-down") {
      return selectedItemIndex < listLength - 1 ? linkStates(selectedItemIndex + 1) : linkStates(0)
    }
  }

  return (
    <List textAlign={textAlign} bgColor="transparent" fontSize="4xl">
      {arrows && (
        <VSListItem
          onClick={e => handleSelect(e, undefined, selectOptions.length)}
          textAlign={textAlign}
          id="vs-up"
        >
          <ListIcon as={FaArrowUp} m={0} />
        </VSListItem>
      )}

      {selectOptions.map((option, i) => (
        <VSListItem
          onClick={e => handleSelect(e, i, selectOptions.length)}
          selected={selectedItemIndex === i}
          textAlign={textAlign}
          key={i}
          itemFontSize={itemFontSize}
        >
          {option}
        </VSListItem>
      ))}

      {arrows && (
        <VSListItem
          onClick={e => handleSelect(e, undefined, selectOptions.length)}
          textAlign={textAlign}
          id="vs-down"
        >
          <ListIcon as={FaArrowDown} m={0} />
        </VSListItem>
      )}
    </List>
  )
}

VerticalSelect.defaultProps = {
  selectOptions: [
    "HonestDoor Price",
    "Sold Prices",
    "Property Details",
    "Permits",
    "City Assessment",
  ],
  textAlign: "right",
  arrows: true,
  itemFontSize: "3xl",
}

export default VerticalSelect
