import Image from "next/legacy/image"
import { FaArrowRight } from "react-icons/fa"
import InternalLink from "~/components/elements/InternalLink"
import { SIHLoader } from "~/utils"

import { Button } from "@chakra-ui/button"
import {
  Container,
  Flex,
  Grid,
  GridItem,
  HStack,
  Heading,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/layout"

import HappyCouplePriceIncrease from "./HappyCouplePriceIncrease"

const defaultSubTextComponent = (
  <HStack>
    <Text fontSize="xl" color="white" fontWeight="400">
      Along with thousands of other Canadians
    </Text>
    <Text fontSize="xl" color="white">
      <FaArrowRight />
    </Text>
  </HStack>
)

const UpdateHonestdoorPrice = (props: {
  title?: string
  buttonText?: string
  subtext?: JSX.Element
  buttonAction?: () => void
  containerMargin?: string
}): JSX.Element => {
  const {
    title = "Update facts on your property to improve your HonestDoor Price",
    buttonText = "Search for a Property",
    subtext = defaultSubTextComponent,
    buttonAction = null,
    containerMargin = "90px",
  } = props

  return (
    <Flex position="relative" zIndex={0} bgColor="black">
      <Flex position="absolute" top="0" left="0" right="0" bottom="0" zIndex={-10} opacity={0.15}>
        <Image
          loader={SIHLoader}
          src="landing/landing-map-image.png"
          alt="Update honestsoor price section background image"
          layout="fill"
          objectFit="cover"
        />
      </Flex>
      <Container maxW="container.xl" my={containerMargin} px={"auto"}>
        <Grid templateColumns={["1fr", "1fr", "1fr", "1fr", "50% 50%"]}>
          <GridItem>
            <Flex justify="center" my="10%" mx="4%">
              <VStack align={["center", "start"]}>
                <Heading fontSize={["xl", "2xl", "3xl", "4xl"]} color="white" fontWeight="500">
                  {title}
                </Heading>
                <Spacer />
                {subtext}
                <Spacer />
                {buttonAction ? (
                  <Button w="fit-content" variant="white-rounded" size="lg" onClick={buttonAction}>
                    {buttonText}
                  </Button>
                ) : (
                  <InternalLink href={"/#featured"} passHref>
                    <Button w="fit-content" variant="white-rounded" size="lg">
                      {buttonText}
                    </Button>
                  </InternalLink>
                )}
              </VStack>
            </Flex>
          </GridItem>
          <GridItem display="flex" alignItems="center" justifyContent="center" w={["100vw", "100%"]} px="0">
            <Flex w="100%" justify="center">
              <HappyCouplePriceIncrease />
            </Flex>
          </GridItem>
        </Grid>
      </Container>
    </Flex>
  )
}

export default UpdateHonestdoorPrice
