import { AnimatePresence, LazyMotion, domAnimation, m } from "framer-motion"
import Image from "next/legacy/image"
import { useState } from "react"
import { FaArrowLeft, FaArrowRight, FaCircle } from "react-icons/fa"
import { useSwipeable } from "react-swipeable"
import { SIHLoader } from "~/utils"

import { Box, BoxProps, Container, Grid, GridItem, HStack, VStack } from "@chakra-ui/layout"
import { Icon, IconButton, chakra } from "@chakra-ui/react"

import VerticalSelect from "../../elements/VerticalSelect"
import styles from "./FeatureCarousel.module.css"

export type TFeatureCarouselProps = BoxProps & {
  slides: { heading: string; text: () => JSX.Element; imgSrc: string }[]
}

const phoneScreens = [
  "landing/phones/screen-hd-price.png",
  "landing/phones/screen-transactions.png",
  "landing/phones/screen-property-info.png",
  "landing/phones/screen-image-permits.png",
  "landing/phones/screen-city-assessed-price.png",
]

const phoneShellSrc = "landing/phones/resized-shell.png"

const FeatureCarousel = ({ slides }: TFeatureCarouselProps): JSX.Element => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0)

  const handleChange = (selectedIndex: number) => {
    // check ordinality for m direction...
    // https://chakra-ui.com/docs/components/transitions#slide-fade-transition
    setActiveSlideIndex(selectedIndex)
  }

  const handleSlideRight = () => {
    if (activeSlideIndex - 1 < 0) {
      setActiveSlideIndex(slides.length - 1)
    } else {
      setActiveSlideIndex(slide => slide - 1)
    }
  }

  const handleSlideLeft = () => {
    if (activeSlideIndex + 1 > slides.length - 1) {
      setActiveSlideIndex(0)
    } else {
      setActiveSlideIndex(slide => slide + 1)
    }
  }

  const { ref } = useSwipeable({
    onSwipedRight: handleSlideRight,
    onSwipedLeft: handleSlideLeft,
  })

  return (
    <LazyMotion features={domAnimation}>
      <Container maxW="container.xl">
        <Grid
          ref={ref}
          gridTemplateColumns={[
            "1fr 2fr",
            "1fr 2fr",
            "minmax(450px, auto) minmax(200px, 2fr)",
            "minmax(450px, auto) minmax(200px, 2fr)",
            "minmax(300px, auto) minmax(450px, auto) minmax(200px, 2fr)",
          ]}
          gridTemplateRows={["1fr 120px", "1fr 120px", "initial"]}
          alignItems="center"
        >
          <GridItem minW="fit-content" display={["initial", "initial", "none", "none", "initial"]}>
            <VerticalSelect
              selectOptions={slides.map(slide => slide.heading)}
              onChange={number => handleChange(number)}
              itemFontSize={["16px", "18px", "2xl"]}
              activeSlide={activeSlideIndex}
            />
          </GridItem>
          <GridItem display="flex" maxW="100%" justifyContent="center" overflowY="hidden"  marginLeft={["24px", 0, 0]}>
            <Box position="relative" mb="-6px" w="100%" h="100%" maxW={400}>
              <Image
                className={styles.phoneShell}
                alt="Landing page phone screen shell"
                loader={SIHLoader}
                src={phoneShellSrc}
                width={400}
                height={523}
                priority={true}
              />
              <Box
                position="absolute"
                top="8%"
                right="14.5%"
                left="14.5%"
                bottom="7px"
                overflow="hidden"
                borderTopRightRadius="20px"
                borderTopLeftRadius="20px"
                bgColor="white"
              >
                <AnimatePresence initial={false}>
                  <m.div
                    key={activeSlideIndex}
                    initial={{ y: -345, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: 345, opacity: 0 }}
                    transition={{ stiffness: 50, type: "spring" }}
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Image
                      loader={SIHLoader}
                      alt="Phone screen content"
                      src={phoneScreens[activeSlideIndex]}
                      width={368}
                      height={616}
                      priority={true}
                    />
                  </m.div>
                </AnimatePresence>
              </Box>
            </Box>
          </GridItem>
          <GridItem position="relative" h="100%" overflowY="hidden" colSpan={[2, 2, 1]}>
            <AnimatePresence initial={false}>
              <m.div
                key={slides[activeSlideIndex].heading}
                initial={{ y: -150, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: 150, opacity: 0 }}
                transition={{ stiffness: 50, type: "spring" }}
                style={{
                  position: "absolute",
                  height: "100%",
                  display: "flex",
                }}
              >
                <VStack
                  align="left"
                  lineHeight="1.2"
                  justifyContent={["initial", "center", "center"]}
                  minH="100%"
                  w="100%"
                  textAlign={["center", "center", "initial"]}
                >
                  <chakra.div
                    borderLeftColor="gold.500"
                    borderLeftWidth="2px"
                    px={['8px', '8px', '28px']}
                    py="18px"
                  >
                    <chakra.div
                      display={["none", "none", "block", "block", "none"]}
                      fontSize="24px"
                      color="white"
                      fontWeight="400"
                      mb={4}
                    >
                      {slides[activeSlideIndex].heading}
                    </chakra.div>

                    <chakra.div
                      color="white"
                      fontSize="18px"
                    >
                      {slides[activeSlideIndex].text()}
                    </chakra.div>
                  </chakra.div>
                </VStack>
              </m.div>
            </AnimatePresence>
            <HStack
              position="absolute"
              justifyContent={["center", "center", "left"]}
              display={["none", "none", "flex", "flex", "none"]}
              spacing={["inital", "initial", "10"]}
              bottom={["5%", "15%", "20%"]}
              w="100%"
            >
              <IconButton
                display={["none", "none", "initial"]}
                bgColor="white"
                _hover={{ bgColor: "hdBlack.100" }}
                _active={{ bgColor: "hdBlack.100" }}
                borderRadius="3xl"
                aria-label="Left Arrow"
                icon={<Icon color="hdBlack.500" fontSize="xl" as={FaArrowLeft} />}
                onClick={handleSlideRight}
              />
              <HStack>
                {slides.map((_, i) => (
                  <Icon
                    key={i}
                    color={activeSlideIndex === i ? "hdGold.full" : "white"}
                    as={FaCircle}
                    cursor="pointer"
                    onClick={() => setActiveSlideIndex(i)}
                  />
                ))}
              </HStack>
              <IconButton
                display={["none", "none", "initial"]}
                bgColor="white"
                _hover={{ bgColor: "hdBlack.100" }}
                _active={{ bgColor: "hdBlack.100" }}
                borderRadius="3xl"
                aria-label="Left Arrow"
                icon={<Icon color="hdBlack.500" fontSize="xl" as={FaArrowRight} />}
                onClick={handleSlideLeft}
              />
            </HStack>
          </GridItem>
        </Grid>
      </Container>
    </LazyMotion>
  )
}

FeatureCarousel.defaultProps = {
  slides: [
    {
      heading: "HonestDoor Price",
      text: () => (
        <>
          Our HonestDoor Price is generated using AI. It provides an instant estimate of property
          value
        </>
      ),
      imgSrc: "/images/phones/phoneimg/Hero Image HD Price.png",
    },
    {
      heading: "Sold Prices",
      text: () => (
        <>You can find out how much a property last sold for, and the transactions before that</>
      ),
      imgSrc: "/images/phones/phoneimg/Hero Image Sold Prices.png",
    },
    {
      heading: "Property Details",
      text: () => <>Find out beds, baths, square footage, land size, year built, and zoning</>,
      imgSrc: "/images/phones/phoneimg/Hero Image Property Info.png",
    },
    {
      heading: "Permits",
      text: () => (
        <>Find permits such as additions, basement developments, hot tubs, and new construction</>
      ),
      imgSrc: "/images/phones/phoneimg/Hero Image Permits.png",
    },
    {
      heading: "City Assessment",
      text: () => (
        <>
          Find out at what price your city has estimated your property value; as well as previous
          years
        </>
      ),
      imgSrc: "/images/phones/phoneimg/Hero Image City Assessment Prices.png",
    },
  ],
}
export default FeatureCarousel
