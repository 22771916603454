import { Container, Flex, Grid, GridItem, Heading, Stack } from "@chakra-ui/layout"
import { Img } from "@chakra-ui/react"

type Features = {
  name: string
  url?: string
  alt?: string
  image?: string
  imageHover?: string
}

const featuredIns: Features[] = [
  {
    name: "CBC News",
    url: "https://www.cbc.ca/news/canada/edmonton/real-estate-edmonton-technology-1.5350349",
    alt: "CBC News logo",
    image: "/images/logo-cbc-news-grayscale.svg",
  },
  {
    name: "Global News",
    url:
      "https://globalnews.ca/news/5374875/real-estate-website-edmonton-property-sale-value-honest-door/",
    alt: "Global News logo",
    image: "/images/logo-global-news-grayscale.svg",
  },
  {
    name: "The Globe and Mail",
    url:
      "https://www.theglobeandmail.com/real-estate/the-market/article-what-does-700k-buy-across-canada-properties-priced-at-or-around-the/",
    alt: "The Globe and Mail logo",
    image: "/images/logo-globe-and-mail-grayscale.svg",
  },
  {
    name: "Calgary Herald",
    url:
      "https://calgaryherald.com/life/homes/new-homes/honest-door-brings-useful-data-to-home-buying-experience",
    alt: "Calgary Herald logo",
    image: "/images/logo-calgary-herald-grayscale.svg",
  },
  {
    name: "Edmonton Journal",
    url: "https://edmontonjournal.com/life/homes/real-estate-info-at-your-fingertips",
    alt: "Edmonton Journal logo",
    image: "/images/logo-edmonton-journal-grayscale.svg",
  },
  {
    name: "Betakit",
    url: "https://betakit.com/eight-prairie-startups-to-watch-in-2020/",
    alt: "Betakit logo",
    image: "/images/logo-betakit-grayscale.svg",
  },
  {
    name: "Daily Hive",
    url: "https://dailyhive.com/vancouver/free-online-real-estate-platform-expanding-bc",
    alt: "Daily Hive logo",
    image: "/images/dailyhive-grayscale.svg",
  },
]

const FeaturedIn = (): JSX.Element => {
  return (
    <Flex bg="#f2f2f2" bgPos="center" data-cy="featured-in">
      <Container my="90px" maxW={["95%", "95%", "90%"]}>
        <Stack
          direction={["column", "column", "column", "column", "row"]}
          w="100%"
          spacing={20}
          alignItems={["left", "left", "left", "left", "center"]}
        >
          <Heading
            as="h1"
            fontSize="22px"
            fontWeight="400"
            fontStyle="italic"
            color="hdBlack.500"
            whiteSpace="nowrap"
          >
            As featured in
          </Heading>
          <Grid
            gridTemplateColumns="repeat(auto-fit, minmax(50px, auto))"
            gap={[10, 10, 10, 20]}
            rowGap={10}
            alignItems="center"
            w="100%"
          >
            {featuredIns.map(f => (
              <GridItem key={f.name}>
                <a href={f.url} target="_blank" rel="noreferrer">
                  <Img alt={f.alt} w="100%" maxW="128px" h="100%" src={f.image} />
                </a>
              </GridItem>
            ))}
          </Grid>
        </Stack>
      </Container>
    </Flex>
  )
}

export default FeaturedIn
