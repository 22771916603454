import { Container } from "@chakra-ui/layout"
import { MultiCarousel } from "components/modules"
import { SortOption } from "~/store/creaFilters"
import { CreaListing } from "~/generated/graphql"
import { PropertyCard } from "~/components/helpers/cards"
import { ContainerProps } from "@chakra-ui/react"

type TMostExpensiveListingsProps = {
  creaListings: CreaListing[]
  containerProps?: ContainerProps
}

const MostExpensiveListings = ({ creaListings, containerProps }: TMostExpensiveListingsProps): JSX.Element => {
  const moreHref = `/real-estate?filters=${encodeURIComponent(
    JSON.stringify({
      crea: {
        sort: SortOption.MOST_EXPENSIVE,
      },
    }),
  )}`

  return (
    <Container maxW="100%" pt={["15px", "15px", "35px"]} px={["0", "0", "4", "10"]} {...containerProps}>
      <MultiCarousel
        title="Most Expensive Listings Today"
        link={{
          label: "See More Listings",
          href: moreHref,
        }}
        loop={true}
        sizes={{ "3xl": 4, "2xl": 3, xl: 3, lg: 2, md: 2, sm: 1 }}
      >
        {creaListings?.map((listing, i) => {
          return <PropertyCard.ForSale key={i} publicListing={listing} />
        })}
      </MultiCarousel>
    </Container>
  )
}

export default MostExpensiveListings
