import Image from "next/legacy/image"
import { Card } from "@chakra-ui/react"
import InternalLink from "~/components/elements/InternalLink"
import SpartaStat from "~/components/elements/SpartaStat"
import { SIHLoader } from "~/utils"
import { Button } from "@chakra-ui/button"
import {
  Center,
  Container,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Stack,
  VStack,
} from "@chakra-ui/layout"

const NeighbourhoodOverview = (): JSX.Element => {
  return (
    <Flex position="relative" zIndex={0} bgColor="white" data-cy="neighbourhood-overview">
      <Flex position="absolute" top="0" left="0" right="0" bottom="0" zIndex={-10}>
        <Image
          loader={SIHLoader}
          alt="Landing page neighbourhood section background"
          src="landing/map-bg-static.png"
          layout="fill"
          objectFit="cover"
        />
      </Flex>
      <Container maxW="container.xl" my="90px">
        <Grid
          gridTemplateColumns={["1fr", "1fr", "1fr", "6fr 4fr"]}
          gap="20"
          alignItems="center"
          justifyItems="center"
        >
          <GridItem justifyItems="center" w="100%">
            <Card w="100%" boxShadow="md" padding="0">
              <Flex
                bgColor="white"
                p="17px 20px"
                borderBottomWidth="2px"
                alignItems={["start", "start", "start", "center"]}
                flexDir={["column", "column", "column", "row"]}
              >
                <Heading fontSize="27px" fontWeight="normal" as="h3">
                  Neighbourhood Overview&nbsp;
                  <Heading fontSize="27px" fontWeight="normal" as="span" color="hdBlack.400">
                    Glenora
                  </Heading>
                </Heading>
              </Flex>
              <Flex
                bgImage={`url('${SIHLoader({
                  src: "landing/map-bg-white.png",
                  width: null,
                })}')`}
                bgPos="center"
                justifyContent="center"
                p={["7", "7", "10", "10"]}
              >
                <Card w="fit-content" minW="fit-content" boxShadow="md">
                  <Stack
                    direction={["column", "column", "row"]}
                    w="100%"
                    spacing="4"
                    h={["auto", "auto", "100%"]}
                    alignItems="center"
                  >
                    <SpartaStat
                      heading="Fastest Growing Neighbourhood"
                      value={203}
                      subText="out of 400"
                      valueProps={{ color: "hdGold.full" }}
                    />
                    <Center display={["none", "none", "initial"]} height="175px" alignSelf="center">
                      <Divider orientation="vertical" />
                    </Center>
                    <Divider display={["initial", "initial", "none"]} orientation="horizontal" />
                    <SpartaStat
                      heading="Most Expensive Neighbourhood"
                      value={13}
                      subText="out of 400"
                      valueProps={{ color: "hdMoney.full" }}
                    />
                    <Center display={["none", "none", "initial"]} height="175px" alignSelf="center">
                      <Divider orientation="vertical" />
                    </Center>
                    <Divider display={["initial", "initial", "none"]} orientation="horizontal" />
                    <SpartaStat
                      heading="Number of Transactions"
                      value={40}
                      subText="out of 400"
                      valueProps={{ color: "hdSky.full" }}
                    />
                  </Stack>
                </Card>
              </Flex>
            </Card>
          </GridItem>
          <GridItem gridRow={["1", "1", "1", "initial"]}>
            <VStack align="left" spacing="8">
              <Heading color="hdBlack.500" as="h2">
                Explore and compare cities and neighbourhoods
              </Heading>
              <InternalLink href="/cities" passHref>
                <Button size="lg" variant="black-rounded" w="fit-content">
                  Start Exploring
                </Button>
              </InternalLink>
            </VStack>
          </GridItem>
        </Grid>
      </Container>
    </Flex>
  )
}

export default NeighbourhoodOverview
