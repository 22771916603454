import { Heading, Text, VStack, Card } from "@chakra-ui/react"
import { IoCloseOutline } from "react-icons/io5"
import theme from "~/theme"

const PriceIncreaseCard = ({ isSmall }: { isSmall?: boolean }) => {
  /* fonts */
  const headingFontSize = isSmall ? "14px" : "26px"
  const headingMargin = isSmall ? "10px" : "20px"
  const fromToFontSize = isSmall ? "12px" : "18px"
  const basedInfoFontSize = isSmall ? "11px" : "18px"

  /* card */
  const cardWidth = isSmall ? 180 : 320
  const cardHeight = isSmall ? 250 : 370
  const cardLeftMargin = isSmall ? "-20px" : "-30px"

  /* svg */
  const svgWidth = isSmall ? 160 : 231
  const svgHeight = isSmall ? 70 : 90
  const svgFontSize = isSmall ? 23 : 45
  const svgArrowScale = isSmall ? 0.04 : 0.07
  const svgArrowTransform = isSmall ? "translate(35, 16)" : "translate(30, 10)"

  /* bars */
  const barCount = isSmall ? 9 : 13
  const barWidth = 15
  const barSpacing = 3
  const barHeights = Array(barCount)
    .fill(true)
    .map((_, i) => 4 + i * 7 + Math.floor(Math.random() * 7))

  return (
    <Card
      color="black"
      w={cardWidth}
      h={cardHeight}
      ml={cardLeftMargin}
      borderRadius={3}
      padding="0"
      px="2"
      py="4"
    >
      <VStack justify="flex-end" align="flex-end" w="100%" m="0">
        <IoCloseOutline />
      </VStack>
      <VStack align="center" spacing="5px">
        <Heading fontSize={headingFontSize} my={headingMargin} textAlign="center" mb={[0, "10px"]}>
          Your HonestDoor Price has increased by
        </Heading>
        <svg width={svgWidth} height={svgHeight} fill={theme.colors.hdBlack[100]}>
          {barHeights.map((h, i) => (
            <rect
              key={i}
              width={barWidth}
              height={h}
              x={i * (barWidth + barSpacing)}
              y={svgHeight - h}
            />
          ))}

          <text
            x={svgWidth / 2}
            y={svgHeight / 2}
            textAnchor="middle"
            fill={theme.colors.hdMoney["full"]}
            fontWeight="bold"
            fontFamily="Roboto"
            fontSize={svgFontSize}
          >
            $29K
          </text>
          <g
            fill={theme.colors.hdMoney["full"]}
            transform={`${svgArrowTransform} scale(${svgArrowScale})`}
          >
            <path d="M34.9 289.5l-22.2-22.2c-9.4-9.4-9.4-24.6 0-33.9L207 39c9.4-9.4 24.6-9.4 33.9 0l194.3 194.3c9.4 9.4 9.4 24.6 0 33.9L413 289.4c-9.5 9.5-25 9.3-34.3-.4L264 168.6V456c0 13.3-10.7 24-24 24h-32c-13.3 0-24-10.7-24-24V168.6L69.2 289.1c-9.3 9.8-24.8 10-34.3.4z" />
          </g>
        </svg>
        <Text fontSize={fromToFontSize} fontWeight="bold" m={0}>
          <span>From </span>
          <Text as={"span"} color="hdGold.full" sx={{ display: "inline" }}>
            $826,000
          </Text>
          <span> to </span>
          <Text as={"span"} color="hdMoney.full" sx={{ display: "inline" }}>
            $855,000
          </Text>
        </Text>
        <Text align="center" fontSize={basedInfoFontSize} m={0}>
          Based on the information you submitted. Thanks!
        </Text>
      </VStack>
    </Card>
  )
}

export default PriceIncreaseCard
