import { FC } from "react"
import { Container } from "@chakra-ui/layout"
import { MultiCarousel } from "components/modules"
import { EsProperty } from "~/generated/graphql"
import { PropertyCard } from "~/components/helpers/cards"
import { ContainerProps } from "@chakra-ui/react"

interface IRecentlySoldPropertiesProps {
  properties: EsProperty[]
  containerProps?: ContainerProps
}

export const RecentlySoldProperties: FC<IRecentlySoldPropertiesProps> = ({ properties, containerProps }) => {
  return (
    <Container
      maxW="100%"
      py={["25px", "25px", "75px"]}
      px={["0", "0", "4", "10"]}
      data-cy="recently-sold"
      {...containerProps}
    >
      <MultiCarousel
        title="Recently Sold Properties"
        titleSuffix="in Canada"
        link={{ label: "See More Recently Sold", href: "/recently-sold" }}
        loop={true}
        sizes={{ "3xl": 4, "2xl": 3, xl: 3, lg: 2, md: 2, sm: 1 }}
      >
        {properties?.map((property, i) => {
          return <PropertyCard.RecentlySold key={i} property={property} />
        })}
      </MultiCarousel>
    </Container>
  )
}
