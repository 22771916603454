import Image from "next/legacy/image"
import { useMediaQuerySSR } from "~/components/hooks"
import { SIHLoader } from "~/utils"
import { Box, Flex, Grid, GridItem } from "@chakra-ui/react"
import PriceIncreaseCard from "./PriceIncreaseCard"

const HappyCouplePriceIncrease = () => {
  const isSmallerThan768 = useMediaQuerySSR(768)
  return (
    <Grid templateColumns={["repeat(2, 170px)", "repeat(2, 190px)", "repeat(2, 320px)"]}>
      <GridItem zIndex={100}>
        <Flex direction="column" justify="center" height="100%">
          <Box borderRadius="full" overflow="hidden">
            <Image
              width={319}
              height={319}
              loader={SIHLoader}
              src="landing/landing-couple.png"
              alt="Laughing couple looking at a laptop computer absolutely loving the price increase HonestDoor will give them after they measure all the studs and confirm they are all the same size"
            />
          </Box>
        </Flex>
      </GridItem>
      <GridItem>
        <Flex direction="column" justify="center" height="100%">
          <PriceIncreaseCard isSmall={isSmallerThan768} />
        </Flex>
      </GridItem>
    </Grid>
  )
}

export default HappyCouplePriceIncrease
