import { FC } from "react"
import { Button, Heading, VStack } from "@chakra-ui/react"
import { chakra } from "@chakra-ui/system"
import InternalLink from "~/components/elements/InternalLink"
import { ButtonVariants } from "~/theme/components/Button"

export const HonestDoorForBusinessBanner: FC = () => {
  return (
    <VStack py="40px" mb="40px" backgroundColor="hdBlack.50" spacing="20px">
      <Heading mx={"1em"} fontSize={["22px", "28px", "32px", "36px"]}>
        Learn how HonestDoor can help your <chakra.span color="hdGold.full">Business</chakra.span>
      </Heading>
      <InternalLink
        href="/for-business"
        _hover={{ textDecoration: "none" }}
      >
        <Button
          variant={ButtonVariants.BlackRounded}
          backgroundColor="hdGold.full"
          _hover={{ backgroundColor: "hdGold.600" }}
        >
          Learn more
        </Button>
      </InternalLink>
    </VStack>
  )
}
