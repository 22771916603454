import { formatISO, subMonths } from "date-fns"
import { subDays } from "date-fns/subDays"
import { GetServerSideProps } from "next"
import { NextSeo } from "next-seo"
import getConfig from "next/config"
import { LandingLayout } from "~/components/layouts/landing"
import { initGQLClient } from "~/graphql/client/ssrClient"
import metadata from "~/utils/seo/metadata"
import { CreaListing, EsProperty, PrivateListing, PrivateListingHdStatus } from "~/generated/graphql"

const { ENV } = getConfig().publicRuntimeConfig

const isDev = ENV !== "production"

interface LandingPageProps {
  properties: EsProperty[]
  creaListings: CreaListing[]
  privateListings: PrivateListing[]
}

const LandingPage = (props: LandingPageProps): JSX.Element => {
  return (
    <>
      <NextSeo {...metadata.landing()} />
      <LandingLayout {...props} />
    </>
  )
}

export const getServerSideProps: GetServerSideProps = async () => {
  const gqlClient = initGQLClient()

  const propertyData = await gqlClient.filterListProperties({
    size: 8,
    closeDate: [formatISO(subMonths(Date.now(), 4))],
    closePrice: [35000],
  })

  const dateRange = isDev
    ? [subDays(Date.now(), 60), Date.now()]
    : [subDays(Date.now(), 1), Date.now()]

  const listingData = await gqlClient.filterCreaListings({
    sortBy: "price",
    sortDirection: "desc",
    createdAtRange: dateRange,
    size: 8,
  })

  const privateListingData = await gqlClient.getPrivateListings({
    filter: {
      hasPropertyId: true,
      hdListingStatus: PrivateListingHdStatus.Active,
    },
    take: 8,
    // @ts-ignore
    order: { updatedAt: "desc" },
  })

  return {
    props: {
      properties: propertyData?.filterListProperties?.propertiesList || [],
      creaListings: listingData?.filterCreaListings?.creaListings || [],
      privateListings: privateListingData?.getPrivateListings || []
    }
  }
}

export default LandingPage
