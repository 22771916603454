import { FC } from "react"
import Image from "next/legacy/image"
import { useRouter } from "next/router"
import { Flex, Stack } from "@chakra-ui/layout"
import { Heading, Button, Box } from "@chakra-ui/react"
import { FaMapMarkedAlt } from "react-icons/fa"
import { ListOnMlsPromo, Search } from "components/modules"
import { SIHLoader } from "~/utils"
import { colors } from "~/theme/colors"
import { DefaultLayout } from "~/components/layouts"

export const Features: FC = () => {
  const { push } = useRouter()

  return (
    <Flex
      pt="76px"
      flexDir="column"
      alignItems="center"
      position="relative"
      zIndex={10}
      data-cy="features"
    >
      <Flex position="absolute" top="0" left="0" right="0" bottom="0" zIndex={-10}>
        <Image
          loader={SIHLoader}
          src="landing/landing-houses.png"
          alt="Landing page background image"
          objectFit="cover"
          layout="fill"
          priority={true}
        />
      </Flex>
      <Flex
        position="absolute"
        top="0"
        right="0"
        left="0"
        bottom="0"
        opacity={0.75}
        bgColor="black"
        zIndex={-8}
      />
      <Stack
        direction={["column", "column", "row"]}
        mb="6"
        mt="10"
        px={["4", "4", "4", "initial"]}
        w="100%"
        justifyContent="center"
        alignItems={["center", "center", "flex-end", "flex-end"]}
        spacing={5}
      >
        <Stack maxW="700px" w="100%">
          <Heading
            textColor="white"
            fontSize="24px"
            fontWeight="400"
            lineHeight="38px"
            textAlign={["center", "center", "left"]}
          >
            <span style={{ color: colors.gold["500"] }}>Track</span> the value of your home
          </Heading>
          <Search
            categories={["Properties", "Cities", "Neighbourhoods"]}
            size="lg"
            placeholder="Search your address"
            maxW="700px"
            w="100%"
            zIndex={100}
            inputProps={{
              fontSize: [16, 18, 20],
            }}
          />
        </Stack>
        <Button
          leftIcon={<FaMapMarkedAlt />}
          size="lg"
          variant="white-rounded"
          backgroundColor={"hdGold.full"}
          color={"white"}
          display={["initial", "initial", "none"]}
          onClick={() => {
            push("/map")
          }}
        >
          Map
        </Button>
        <Button
          leftIcon={<FaMapMarkedAlt />}
          size="md"
          variant="white-rounded"
          display={["none", "none", "initial"]}
          onClick={() => {
            push("/map")
          }}
        >
          Map
        </Button>
      </Stack>

      <Box width="100%" mt={[0, 0, 0, 12]}>
        <DefaultLayout>
          <ListOnMlsPromo variant="homepage" />
        </DefaultLayout>
      </Box>
    </Flex>
  )
}
