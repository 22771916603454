import { FC, useMemo } from "react"
import { Container, Box, Flex } from "@chakra-ui/react"
import Image from "next/legacy/image"
import { LinkList } from "components/modules"
import UpdateHonestdoorPrice from "~/components/modules/UpdateHonestdoorPrice/UpdateHonestdoorPrice"
import FeaturedIn from "./sections/FeaturedIn"
import { Features } from "./sections/features"
import { HonestDoorForBusinessBanner } from "./sections/HonestDoorForBusinessBanner"
import MostExpensiveListings from "./sections/MostExpensiveListings"
import NeighbourhoodOverview from "./sections/NeighbourhoodOverview"
import Newsletter from "./sections/Newsletter"
import ProudlyCanadian from "./sections/ProudlyCanadian"
import { RecentlySoldProperties } from "./sections/RecentlySoldProperties"
import { ExclusiveListings } from "./sections/ExclusiveListings"
import { createPopularCitiesLinks } from "~/utils/helpers"
import { CreaListing, EsProperty, PrivateListing } from "~/generated/graphql"
import { DefaultLayout } from "~/components/layouts"
import FeatureCarousel from "~/components/modules/FeatureCarousel"
import { SIHLoader } from "~/utils"

interface ILandingLayoutProps {
  creaListings: CreaListing[]
  properties: EsProperty[]
  privateListings: PrivateListing[]
}

export const LandingLayout: FC<ILandingLayoutProps> = ({
  creaListings,
  properties,
  privateListings,
}) => {
  const cityLinks = useMemo(createPopularCitiesLinks, [])

  return (
    <Flex flexDirection="column" overflowX="hidden" id="featured">
      <Features />

      <DefaultLayout>
        {privateListings.length > 0 && (
          <ExclusiveListings
            privateListings={privateListings}
            containerProps={{ px: 0, pt: 0 }}
          />
        )}
        {properties.length > 0 && (
          <RecentlySoldProperties properties={properties} containerProps={{ px: 0 }} />
        )}
        {creaListings.length > 0 && (
          <MostExpensiveListings creaListings={creaListings} containerProps={{ px: 0 }} />
        )}
      </DefaultLayout>

      <Box position="relative" mt="24px">
        <Flex position="absolute" top="0" left="0" right="0" bottom="0" zIndex={-10}>
          <Image
            loader={SIHLoader}
            src="landing/landing-houses.png"
            alt="Landing page background image"
            objectFit="cover"
            layout="fill"
            priority={true}
          />
        </Flex>
        <FeatureCarousel zIndex={10} />
      </Box>

      <FeaturedIn />

      <UpdateHonestdoorPrice />
      <HonestDoorForBusinessBanner />
      <NeighbourhoodOverview />
      <Newsletter />
      <ProudlyCanadian />
      <Container
        maxW="1800px"
        py={["65px", "65px", "75px"]}
        px={["4", "4", "4", "10"]}
        data-cy="current-cities"
      >
        <LinkList
          categoryDir="vertical"
          items={[{ category: "Popular Cities", links: cityLinks }]}
        />
      </Container>
    </Flex>
  )
}
